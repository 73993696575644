import { FC } from 'react'
import { createGlobalStyle } from 'styled-components'
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'

const CustomStyles = createGlobalStyle`
  body {
    -webkit-tap-highlight-color: ${theme`colors.blue.900`};
    ${tw`antialiased font-condensed bg-dark-800 text-white m-0`}
  }

  button {
    ${tw`uppercase cursor-pointer transition duration-300	ease-in-out`}

    &:focus {
      ${tw`outline-none`}
    }
  }

  img {
    ${tw`select-none`}
    -webkit-user-drag: none;
  }

  a {
    ${tw`no-underline`}
  }

  h1, h2, h3, h4, h5, h6, p {
    ${tw`m-0`}
  }
`

const GlobalStyles: FC = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles
