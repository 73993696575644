import { useWeb3React } from '@web3-react/core'
import { useEffect, useRef, useState } from 'react'
import { INJECTED_CONNECTOR } from '../constants'

const useEagerConnect = () => {
  const ref = useRef(false)
  const { activate, active } = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    INJECTED_CONNECTOR.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized && ref.current) {
        activate(INJECTED_CONNECTOR, undefined, true).catch(() => {
          if (ref.current) {
            setTried(true)
          }
        })
      } else {
        if (ref.current) {
          setTried(true)
        }
      }
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  return tried
}

export default useEagerConnect
