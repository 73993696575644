import { FC, useCallback, useEffect } from 'react'
import 'twin.macro'
import { ScaleLoader } from 'react-spinners'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
import {
  useEagerConnect,
  useEthereumInactiveListener,
  useWeb3Provider,
} from './hooks'
import ConnectPage from './pages/ConnectPage'
import ClaimPage from './pages/ClaimPage'

interface IAppRouteProps extends RouteProps {
  isConnecting: boolean
}

const AppRoute: FC<IAppRouteProps> = ({
  isConnecting,
  component: Component,
  ...restProps
}) => {
  const { active } = useWeb3Provider()

  return (
    <Route
      {...restProps}
      render={(props) => {
        if (!isConnecting && !active && restProps.path !== '/') {
          return <Redirect to="/" />
        }
        return isConnecting ? (
          <div tw="flex justify-center">
            <ScaleLoader color="#ffffff" />
          </div>
        ) : (
          Component && <Component {...props} />
        )
      }}
    />
  )
}

const App: FC = () => {
  useEthereumInactiveListener()
  const tried = useEagerConnect()
  const { active } = useWeb3Provider()

  const onAccountsChanged = useCallback(() => {
    window.location.reload()
  }, [])

  useEffect(() => {
    // @ts-ignore
    window?.ethereum?.on('accountsChanged', onAccountsChanged)

    return () => {
      // @ts-ignore
      window?.ethereum?.removeListener('accountsChanged', onAccountsChanged)
    }
  }, [onAccountsChanged])

  return (
    <div
      tw="w-screen max-w-full min-h-screen flex justify-center"
      style={{
        backgroundColor: '#222732',
      }}
    >
      <Router>
        <Switch>
          {(!active && <ConnectPage />) || (
            <>
              <AppRoute path="/" component={ClaimPage} isConnecting={!tried} />
            </>
          )}
        </Switch>
      </Router>
    </div>
  )
}

export default App
