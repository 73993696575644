import { useWeb3Provider } from './'
import { useMemo } from 'react'
import { getContract } from '../utils'
import {
  DISTRIBUTION_CONTRACT_ABI,
  DISTRIBUTION_CONTRACT_ADDRESS,
} from '../constants'

const useTokenSaleContract = () => {
  const { library, account } = useWeb3Provider()

  return useMemo(() => {
    if (!library || !account) {
      return null
    }

    try {
      return getContract(
        DISTRIBUTION_CONTRACT_ADDRESS,
        DISTRIBUTION_CONTRACT_ABI,
        library,
        account
      )
    } catch (error) {
      return null
    }
  }, [account, library])
}

export default useTokenSaleContract
