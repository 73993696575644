export const getAccount = async () => {
  // @ts-ignore
  const { ethereum } = window

  if (!ethereum) {
    throw new Error('MetaMask not found')
  }

  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  return accounts[0]
}
