const theme = {
  colors: {
    dark900: '#000000',
    dark800: '#0A0A0A',
    dark700: '#161616',
    dark600: '#1C2025',
    dark500: '#1F2328',
    dark400: '#282C34',
    dark300: '#404A55',
    dark200: '#434F5B',
    dark100: '#202329',
    gray900: '#707070',
    gray800: '#777777',
    gray700: '#A4A4A4',
    gray600: '#B9B9B9',
    gray500: '#BFBFBF',
    gray450: '#CBCBCB',
    gray400: '#D2D2D2',
    gray300: '#949494',
    modalOverlayBg: 'rgba(10, 10, 10, .5)',
    panelBg: 'rgba(10, 10, 10, .33)',
    modalDividerColor: 'rgba(112, 112, 112, .15)',
    panelLabel: 'rgba(255, 255, 255, .85)',
    headerBg: `rgba(22, 22, 22, .85)`,
    appHeaderBg: `rgba(10, 10, 10, .83)`,
    drawerMobileBg: `rgba(10, 10, 10, .72)`,
    white: '#FFFFFF',
    blue900: '#319DE2',
    blue800: '#00B3ED',
    blue700: '#5ED8FF',
    primaryText: '#FFFFFF',
    homePanelBg: '#1D1D1D',
  },
  fonts: {
    condensed: {
      xxsLight: 'normal normal 300 9px/11px "Roboto Condensed"',
      xsLight: 'normal normal 300 11px/13px "Roboto Condensed"',
      smLight: 'normal normal 300 12px/14px "Roboto Condensed"',
      baseLight: 'normal normal 300 13px/16px "Roboto Condensed"',
      baseNormal: 'normal normal normal 14px/17px "Roboto Condensed"',
      mdLight: 'normal normal 300 15px/18px "Roboto Condensed"',
      mdNormal: 'normal normal normal 15px/18px "Roboto Condensed"',
      mmdLight: 'normal normal 300 18px/22px "Roboto Condensed"',
      mmdNormal: 'normal normal normal 18px/22px "Roboto Condensed"',
      lgLight: 'normal normal 300 20px/24px "Roboto Condensed"',
      lgNormal: 'normal normal normal 20px/24px "Roboto Condensed"',
      xlLight: 'normal normal 300 22px/27px "Roboto Condensed"',
      xlNormal: 'normal normal normal 22px/27px "Roboto Condensed"',
      xxlLight: 'normal normal 300 24px/28px "Roboto Condensed"',
      xxlNormal: 'normal normal normal 24px/29px "Roboto Condensed"',
      subtitleLight: 'normal normal 300 30px/36px "Roboto Condensed"',
      titleLight: 'normal normal 300 36px/44px "Roboto Condensed"',
      titleNormal: 'normal normal normal 36px/43px "Roboto Condensed"',
      headingLight: 'normal normal 300 64px/72px "Roboto Condensed"',
      bigNumLight: 'normal normal 300 80px/94px "Roboto Condensed"',
      bigNumNormal: 'normal normal normal 80px/94px "Roboto Condensed"',
    },
    roboto: {
      mdNormal: 'normal normal normal 15px/18px "Roboto"',
      mmdNormal: 'normal normal normal 18px/22px "Roboto"',
      lgNormal: 'normal normal normal 20px/24px "Roboto"',
      xlNormal: 'normal normal normal 21px/25px "Roboto"',
      xxlNormal: 'normal normal normal 24px/29px "Roboto"',
      subtitleNormal: 'normal normal normal 26px/32px "Roboto"',
      titleNormal: 'normal normal normal 28px/34px "Roboto"',
      headingNormal: 'normal normal normal 56px/67px "Roboto"',
    },
  },
  sidebar: {
    width: '200px',
  },
}

export default theme
