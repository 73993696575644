import { Web3Provider } from '@ethersproject/providers'
import { Contract } from 'ethers'

export const getContract = (
  address: string,
  ABI: any,
  library: Web3Provider,
  account: string
): Contract => {
  return new Contract(address, ABI, library.getSigner(account))
}
