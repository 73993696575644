import React from 'react'
import tw, { theme, styled } from 'twin.macro'
import { useClaim, useTokenSale } from '../../hooks'
import { PulseLoader } from 'react-spinners'

const Button = styled.button<{ disabled: boolean; inProgress: boolean }>`
  ${tw`border-2 border-blue-800 hover:bg-blue-800 rounded-md py-2 px-4 disabled:cursor-not-allowed disabled:hover:bg-transparent`}
  ${({ disabled, inProgress }) =>
    disabled && !inProgress ? tw`opacity-30` : undefined}
`

const ClaimButton = () => {
  const tokenSale = useTokenSale()
  const { inProgress, claim } = useClaim()

  const disabled = tokenSale.claimable.isZero() || inProgress

  return (
    <Button onClick={claim} disabled={disabled} inProgress={inProgress}>
      {(inProgress && (
        <PulseLoader color={theme`colors.blue.800`} size={10} />
      )) || (
        <div tw="flex flex-row items-center gap-2">
          CLAIM {tokenSale.claimable.toFormat(4)}
          <img src="/svgs/logo-small.svg" alt="BENQI" tw="h-3" />
        </div>
      )}
    </Button>
  )
}

export default ClaimButton
