import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { INJECTED_CONNECTOR } from '../constants'

const useEthereumInactiveListener = (suppress: boolean = false) => {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = (chainId: string | number) => {
        activate(INJECTED_CONNECTOR)
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(INJECTED_CONNECTOR)
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
  }, [active, error, suppress, activate])
}

export default useEthereumInactiveListener
