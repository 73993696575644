import { Web3Provider } from '@ethersproject/providers'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { INJECTED_CONNECTOR } from '../constants'

const useWeb3Provider = () => {
  const ref = useRef(false)
  const { activate, ...restParams } = useWeb3React<Web3Provider>()
  const [loading, setLoading] = useState(false)

  const connect = useCallback(() => {
    setLoading(true)
    activate(INJECTED_CONNECTOR)
      .then(() => {})
      .catch(() => {})
      .then(() => {
        if (ref.current) {
          setLoading(false)
        }
      })
  }, [activate])

  const isUnsupportedChainId = useMemo(
    () => restParams.error instanceof UnsupportedChainIdError,
    [restParams.error]
  )

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  return {
    ...restParams,
    activate: connect,
    isUnsupportedChainId,
    loading,
  }
}

export default useWeb3Provider
