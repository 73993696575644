import React, { FC, useCallback, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import 'twin.macro'
import { INJECTED_CONNECTOR, CHAIN_ID, NETWORK } from '../constants'

const SwitchNetworkPanel: FC = () => {
  const [loading, setLoading] = useState(false)

  const switchNetwork = useCallback(() => {
    setLoading(true)
    INJECTED_CONNECTOR.getProvider().then((provider) => {
      provider
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${CHAIN_ID.toString(16)}`, // A 0x-prefixed hexadecimal chainId
              chainName: NETWORK,
              nativeCurrency: {
                name: 'Avalanche',
                symbol: 'AVAX',
                decimals: 18,
              },
              rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
              blockExplorerUrls: ['https://cchain.explorer.avax-test.network/'],
            },
          ],
        })
        .catch((err: any) => {
          console.log(err)
        })
        .then(() => setLoading(false))
    })
  }, [])

  return (
    <div tw="block m-10 lg:w-4/5 lg:mt-20 md:w-full max-w-screen-md">
      <img src="/svgs/logo.svg" alt="BENQI" tw="h-20 mb-10" />

      <div tw="bg-dark-900 bg-opacity-30 pt-9 pb-10 px-10 rounded-xl">
        <div tw="font-light">
          <h1 tw="text-2xl m-0 p-0">WRONG NETWORK</h1>

          <p tw="mb-10">Please connect to the appropriate Avalanche network.</p>
        </div>

        {loading ? (
          <div tw="mt-2.5">
            <ClipLoader color="#ffffff" size={24} />
          </div>
        ) : (
          <button
            onClick={switchNetwork}
            tw="border-2 border-blue-800 rounded-md py-2 px-4 hover:bg-blue-800"
          >
            SWITCH TO AVALANCHE CHAIN
          </button>
        )}
      </div>
    </div>
  )
}

export default SwitchNetworkPanel
