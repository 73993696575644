import React, { useState } from 'react'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useWeb3Provider, useTokenSale } from '../hooks'
import AddBenqiToMetaMaskButton from '../components/buttons/AddBenqiToMetaMaskButton'
import ClaimButton from '../components/buttons/ClaimButton'
import ReleasesTable from '../components/ReleasesTable'
import api from '../store/services/benqi/benqi'

const ValueContainer = tw.div`flex flex-col gap-2 text-center md:text-left`
const Label = tw.span`font-light text-5xs text-gray-600`
const Value = tw.span`block mt--3 text-sm`

const ClaimPage = () => {
  const { account } = useWeb3Provider()
  const tokenSale = useTokenSale()
  const { isLoading } = api.endpoints.getTokenSaleInfo.useQuery()

  const [isVestingScheduleTableVisible, setIsVestingScheduleTableVisible] =
    useState(false)

  // @ts-ignore
  const datetimeFormatter = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
    timeStyle: 'short',
  })

  const now = new Date().getTime()
  let nextRelease = '—'
  if (tokenSale.releases.length) {
    const i =
      tokenSale.releases.length -
      tokenSale.releases
        .slice()
        .reverse()
        .findIndex(({ datetime }) => datetime < now)

    if (i < tokenSale.releases.length) {
      nextRelease = datetimeFormatter.format(tokenSale.releases[i].datetime)
    }
  }

  return (
    <div tw="block m-10 lg:w-4/5 lg:mt-20 md:w-full max-w-screen-md">
      <div tw="flex flex-row justify-center md:justify-start">
        <img src="/svgs/logo.svg" alt="BENQI" tw="h-20 mb-10" />
      </div>

      <div tw="bg-dark-900 bg-opacity-30 pt-9 pb-10 px-10 rounded-xl">
        <div tw="mb-8 font-light">
          <h1 tw="text-2xl m-0 p-0">TOKEN SALE</h1>

          <div tw="text-4xs text-gray-700">
            <p>
              Thank you for participating in the BENQI sale! You can view the
              amount of QI tokens that is available to claim. New QI tokens can
              be claimed monthly according to the vesting schedule.
            </p>
            <p tw="mt-3">
              Learn more about the sale{' '}
              <Link
                to={{
                  pathname:
                    'https://benqifinance.medium.com/benqi-public-sale-registration-f1784cf05afd',
                }}
                target="_blank"
                rel="noreferrer noopener"
                tw="text-blue-800"
              >
                here
              </Link>
              .
            </p>
          </div>
        </div>

        <span tw="block mb-1 font-light text-right text-5xs text-gray-600">
          Connected wallet: {account}
        </span>

        <div tw="rounded-md bg-dark-300 py-4 px-3 mb-5">
          <div tw="flex flex-col items-center md:flex-row gap-x-10 gap-y-3">
            {(isLoading && (
              <span tw="font-light text-3xs text-gray-600">Loading...</span>
            )) || (
              <>
                <ValueContainer>
                  <Label>Purchased</Label>
                  <Value>{tokenSale.purchased.toFormat(2)} QI</Value>
                </ValueContainer>
                <ValueContainer>
                  <Label>Claimed</Label>
                  <Value>{tokenSale.claimed.toFormat(2)} QI</Value>
                </ValueContainer>
                <ValueContainer tw="md:flex-1">
                  <Label>Available to Claim</Label>
                  <Value>{tokenSale.claimable.toFormat(2)} QI</Value>
                </ValueContainer>
                <ValueContainer tw="md:text-right">
                  <Label>Next Token Release</Label>
                  <Value
                    tw="flex flex-row items-center justify-center md:justify-start"
                    css={
                      tokenSale.purchased.isZero()
                        ? undefined
                        : tw`cursor-pointer`
                    }
                    onClick={() => {
                      if (!tokenSale.purchased.isZero()) {
                        setIsVestingScheduleTableVisible(
                          !isVestingScheduleTableVisible
                        )
                      }
                    }}
                  >
                    {!tokenSale.purchased.isZero() && (
                      <FontAwesomeIcon
                        icon={
                          isVestingScheduleTableVisible
                            ? faChevronUp
                            : faChevronDown
                        }
                        title="Toggle vesting schedule table visibility"
                        tw="mr-2 text-5xs text-gray-400"
                      />
                    )}

                    {nextRelease}
                  </Value>
                </ValueContainer>
              </>
            )}
          </div>

          {isVestingScheduleTableVisible && (
            <div tw="mt-5">
              <ReleasesTable />
            </div>
          )}
        </div>

        <div tw="flex flex-col gap-y-3 items-center justify-between md:flex-row md:gap-x-5">
          <ClaimButton />
          <div tw="flex flex-col gap-y-5 mt-10 items-center text-5xs md:flex-row md:gap-x-6 md:mt-0">
            <AddBenqiToMetaMaskButton />
            <Link
              to={{ pathname: 'https://app.benqi.fi' }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div tw="flex flex-row items-center gap-2">
                <img src="/svgs/logo-small.svg" alt="BENQI" tw="h-3.5" />
                GO TO THE APP
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimPage
