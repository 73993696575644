import { createApi } from '@reduxjs/toolkit/query/react'
import getTokenSaleInfo, {
  SerializableTokenSaleInfo,
} from './endpoints/getTokenSaleInfo'

const benqi = createApi({
  reducerPath: 'benqi',
  baseQuery: () => ({ error: 'missing queryFn' }),
  endpoints: (builder) => ({
    getTokenSaleInfo: builder.query<SerializableTokenSaleInfo, void>({
      queryFn: getTokenSaleInfo,
    }),
  }),
})

export default benqi
