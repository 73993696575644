import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber/lib/bignumber'
import { getAccount, getContract, getProvider } from '../../../../utils'
import {
  DISTRIBUTION_CONTRACT_ABI,
  DISTRIBUTION_CONTRACT_ADDRESS,
} from '../../../../constants'

export type SerializableTokenSaleInfo = {
  claimed: string
  claimable: string
  purchased: string
  releases: { datetime: number; amount: string }[]
}

const getTokenSaleInfo = async () => {
  const account = await getAccount()
  const provider = getProvider()

  const contract = getContract(
    DISTRIBUTION_CONTRACT_ADDRESS,
    DISTRIBUTION_CONTRACT_ABI,
    provider,
    account
  )

  const vestingScheduleEpochPromise = contract.vestingScheduleEpoch()
  const releasePeriodLengthPromise = contract.releasePeriodLength()
  const completedPurchaseRoundsPromise =
    contract.completedPurchaseRounds(account)

  const claimableTokenAmountPromise = contract
    .getClaimableTokenAmount()
    .then((response: EthersBigNumber) =>
      new BigNumber(response.toString()).shiftedBy(-18)
    )

  const claimedTokenAmountPromise = contract
    .getClaimedTokenAmount()
    .then((response: EthersBigNumber) =>
      new BigNumber(response.toString()).shiftedBy(-18)
    )

  return Promise.all([
    vestingScheduleEpochPromise,
    releasePeriodLengthPromise,
    completedPurchaseRoundsPromise,
    claimedTokenAmountPromise,
    claimableTokenAmountPromise,
  ]).then((response: EthersBigNumber[]) => {
    const vestingScheduleEpoch = new BigNumber(response[0].toString())
    const releasePeriodLength = new BigNumber(response[1].toString())
    const completedPurchaseRounds = response[2].toNumber()
    const claimedTokenAmount = new BigNumber(response[3].toString())
    const claimableTokenAmount = new BigNumber(response[4].toString())

    if (completedPurchaseRounds === 0) {
      return {
        data: {
          claimed: '0',
          claimable: '0',
          purchased: '0',
          releases: [],
        },
      }
    }

    const roundDataPromises = Array.apply(
      null,
      Array(completedPurchaseRounds)
    ).map((_, i) => {
      const releasePeriodsPromise = contract
        .releasePeriods(account, i)
        .then(
          (releasePeriods: EthersBigNumber) =>
            new BigNumber(releasePeriods.toString())
        )

      const initialReleasePercentagePromise = contract
        .initialReleasePercentages(account, i)
        .then((initialReleasePercentage: EthersBigNumber) =>
          new BigNumber(initialReleasePercentage.toString()).shiftedBy(-20)
        )

      const purchasedTokensPromise = contract
        .purchasedTokens(account, i)
        .then((purchasedTokens: EthersBigNumber) =>
          new BigNumber(purchasedTokens.toString()).shiftedBy(-18)
        )

      return Promise.all([
        releasePeriodsPromise,
        initialReleasePercentagePromise,
        purchasedTokensPromise,
      ])
    })

    return Promise.all(roundDataPromises).then(
      (rounds: [number, number, BigNumber][]) => {
        const totalPeriods = Math.max(...rounds.map((round) => round[0]))
        const releases = Array.apply(null, Array(totalPeriods)).map((_, i) => ({
          datetime: vestingScheduleEpoch
            .plus(releasePeriodLength.times(i))
            .times(1000)
            .toNumber(),
          amount: new BigNumber(0),
        }))

        const purchasedTokens = rounds.reduce(
          (total, round) => total.plus(round[2]),
          new BigNumber(0)
        )

        rounds.forEach((round) => {
          const [
            roundReleasePeriods,
            roundInitialReleasePercentage,
            roundPurchasedTokens,
          ] = round

          const initialRelease = roundPurchasedTokens.times(
            roundInitialReleasePercentage
          )
          const releasedPerRound = roundPurchasedTokens
            .minus(initialRelease)
            .div(roundReleasePeriods - 1)

          releases[0].amount = releases[0].amount.plus(initialRelease)

          for (let i = 1; i < roundReleasePeriods; i += 1) {
            releases[i].amount = releases[i].amount.plus(releasedPerRound)
          }
        })

        return {
          data: {
            purchased: purchasedTokens.toString(),
            claimed: claimedTokenAmount.toString(),
            claimable: claimableTokenAmount.toString(),
            releases: releases.map(({ datetime, amount }) => ({
              datetime,
              amount: amount.toString(),
            })),
          },
        }
      }
    )
  })
}

export default getTokenSaleInfo
