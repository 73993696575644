import BigNumber from 'bignumber.js'
import api from '../store/services/benqi/benqi'

type TokenSaleInfo = {
  purchased: BigNumber
  claimed: BigNumber
  claimable: BigNumber
  releases: { datetime: number; amount: BigNumber }[]
}

const useTokenSale = (): TokenSaleInfo => {
  const { data } = api.endpoints.getTokenSaleInfo.useQuery()

  return data
    ? {
        purchased: new BigNumber(data.purchased),
        claimed: new BigNumber(data.claimed),
        claimable: new BigNumber(data.claimable),
        releases: data.releases.map(({ datetime, amount }) => ({
          datetime,
          amount: new BigNumber(amount),
        })),
      }
    : {
        purchased: new BigNumber(0),
        claimed: new BigNumber(0),
        claimable: new BigNumber(0),
        releases: [],
      }
}

export default useTokenSale
