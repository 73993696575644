import React, { FC } from 'react'
import 'twin.macro'
import SwitchNetworkPanel from '../components/SwitchNetworkPanel'
import { useWeb3Provider } from '../hooks'

const ConnectPage: FC = () => {
  const { activate, isUnsupportedChainId } = useWeb3Provider()

  return isUnsupportedChainId ? (
    <SwitchNetworkPanel />
  ) : (
    <div tw="block m-10 lg:w-4/5 lg:mt-20 md:w-full max-w-screen-md">
      <img src="/svgs/logo.svg" alt="BENQI" tw="h-20 mb-10" />

      <div tw="bg-dark-900 bg-opacity-30 pt-9 pb-10 px-10 rounded-xl">
        <div tw="font-light">
          <h1 tw="text-2xl m-0 p-0">CONNECT WALLET</h1>

          <p tw="mb-10">To continue, please connect your wallet.</p>
        </div>
        <button
          onClick={activate}
          tw="border-2 border-blue-800 rounded-md py-2 px-4 hover:bg-blue-800"
        >
          CONNECT
        </button>
      </div>
    </div>
  )
}

export default ConnectPage
