import { useCallback, useState } from 'react'
import { useTokenSaleContract } from './'
import api from '../store/services/benqi/benqi'

const useClaim = () => {
  const tokenSaleContract = useTokenSaleContract()
  const getTokenSaleInfo = api.endpoints.getTokenSaleInfo.useQuery()

  const [inProgress, setInProgress] = useState(false)
  const [failed, setFailed] = useState(false)

  const invalidateCache = useCallback(() => {
    getTokenSaleInfo.refetch()
  }, [getTokenSaleInfo])

  const claim = useCallback(() => {
    if (!tokenSaleContract) {
      return
    }

    setInProgress(true)
    setFailed(false)

    tokenSaleContract
      .claim()
      .then(({ wait }: any) => wait())
      .then(() => {
        invalidateCache()
      })
      .catch(() => {
        setFailed(true)
      })
      .finally(() => {
        setInProgress(false)
      })
  }, [tokenSaleContract, invalidateCache])

  return {
    inProgress,
    failed,
    claim,
  }
}

export default useClaim
