import React from 'react'
import 'twin.macro'
import { useWeb3Provider } from '../../hooks'
import { BENQI_ADDRESS, BENQI_DECIMALS } from '../../constants'

const AddBenqiToMetaMaskButton = () => {
  const { library } = useWeb3Provider()

  const onAddBenqiToMetaMaskClick = () => {
    library?.provider.sendAsync?.(
      {
        method: 'metamask_watchAsset',
        params: {
          // @ts-ignore
          type: 'ERC20',
          options: {
            address: BENQI_ADDRESS,
            symbol: 'QI',
            decimals: BENQI_DECIMALS,
            image: 'https://app.benqi.fi/svgs/qi_black_text.svg',
          },
        },
      },
      () => {}
    )
  }

  return (
    <button onClick={onAddBenqiToMetaMaskClick}>
      <div tw="flex flex-row items-center gap-2">
        <img src="/svgs/metamask.svg" alt="MetaMask" tw="h-5" />
        Add QI to MetaMask
      </div>
    </button>
  )
}

export default AddBenqiToMetaMaskButton
