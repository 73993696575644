import React from 'react'
import tw, { styled } from 'twin.macro'
import { down } from 'styled-breakpoints'
import BigNumber from 'bignumber.js'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import { useTokenSale } from '../hooks'

const Table = styled.div<{ isMobile: boolean }>`
  ${tw`grid gap-y-2 p-5 bg-dark-500 rounded-md`}
  ${({ isMobile }) =>
    `grid-template-columns: 1fr repeat(${isMobile ? 1 : 3}, max-content);`}
`

const Header = tw.span`font-bold border-b-2 pb-2 px-3`
const Footer = tw.span`font-bold border-t-2 pt-2 px-3`

const Cell = tw.span`px-3`

const ReleasesTable = () => {
  const tokenSale = useTokenSale()
  const isMobile = useBreakpoint(down('md')) || false

  let cumulativeTokensReleased = new BigNumber(0)

  // @ts-ignore
  const datetimeFormatter = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
    timeStyle: 'short',
  })

  const rows = Array.apply(null, Array(tokenSale.releases.length)).map(
    (_, i) => {
      const tokenAmount = tokenSale.releases[i].amount
      const relativeAmount = tokenAmount.div(tokenSale.purchased).times(100)

      cumulativeTokensReleased = cumulativeTokensReleased.plus(tokenAmount)

      return (
        <React.Fragment key={i}>
          <Cell tw="pl-0">
            {datetimeFormatter.format(tokenSale.releases[i].datetime)}
          </Cell>
          <Cell tw="text-right" css={isMobile ? tw`pr-0` : undefined}>
            {tokenAmount.toFormat(2)} QI
          </Cell>

          {!isMobile && (
            <>
              <Cell tw="text-right">{relativeAmount.toFormat(2)} %</Cell>
              <Cell tw="pr-0 text-right">
                {cumulativeTokensReleased.toFormat(2)} QI
              </Cell>
            </>
          )}
        </React.Fragment>
      )
    }
  )

  return (
    <Table isMobile={isMobile}>
      <Header tw="pl-0">Token Release Date</Header>
      <Header tw="text-right">Released Tokens</Header>
      {!isMobile && (
        <>
          <Header tw="text-right">% of Total</Header>
          <Header tw="pr-0 text-right">Cumulative</Header>
        </>
      )}

      {rows}

      <Footer
        tw="text-right pr-0"
        css={!isMobile ? tw`col-span-4` : tw`col-span-2`}
      >
        Total: {tokenSale.purchased.toFormat(2)} QI
      </Footer>
    </Table>
  )
}

export default ReleasesTable
